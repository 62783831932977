#header {
  background-color: white;
  height: 100px;
  padding: 0;
}

.ant-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.ant-layout-content {
  flex: 1;
}

#footer {
  vertical-align: bottom;
}
