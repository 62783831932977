/* body {
  font-family: sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0;
} */

.word-section {
  padding: 20px;
  margin: auto;
  max-width: 800px;
}

.title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}

.sales-order-table {
  width: 100%;
  border-collapse: collapse;
  /* margin-bottom: 20px; */
}

.sales-order-table th,
.sales-order-table td {
  border: 1px solid black;
  padding: 8px;
  font-size: 10px;
  vertical-align: top;
}

.srno-column {
  width: 30px; /* Adjust the width as needed */
}

.sales-order-table p {
  margin: 0;
}

.text-center {
  text-align: center;
}

.no-border {
  border: none;
}

.footer-text {
  color: #bfbfbf;
  font-size: 10px;
}
