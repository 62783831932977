#about-container {
  text-align: center;
}

.piercing-image-container {
  width: 100%;
  max-height: 700px;
  overflow: hidden;
}

.piercing-banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.content-container {
  padding: 20px;
}

.story-container {
  background-color: #fff;
  padding: 20px;
  max-width: 800px;
}

.story-title {
  font-size: 32px;
  letter-spacing: 1.5px;
}

.story-paragraph {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.features-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.feature-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-content {
  text-align: center;
  max-width: 300px;
}

.section-title {
  font-size: 24px;
  text-align: start;
  margin: 0 !important;
}

.section-paragraph {
  font-size: 18px;
  font-weight: 500;
  text-align: start !important;
}

@media (max-width: 768px) {
  /* .overlay-text {
    left: 50%;
    padding: 5px;
    width: 100%;
  } */
  .piercing-banner-image {
    max-height: 400px;
  }

  .story-title {
    font-size: 24px;
  }

  .section-title {
    font-size: 20px;
  }

  .story-paragraph,
  .section-paragraph {
    font-size: 14px;
  }
}
