@import url("https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

.piercing-image-container {
  position: relative;
  width: 100%;
  max-height: 750px;
  overflow: hidden;
  font-family: "Merriweather", serif;
}

.piercing-banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 750px;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  font-style: italic;
  font-family: "Libre Bodoni", serif;
}

.overlay-title {
  color: #3c6d9c;
  font-size: 20px;
}

#about-container {
  display: flex;
  justify-content: center;
  padding: 50px 20px;
  /* background-color: #f9f9f9; */
}

.content-container {
  /* max-width: 900px; */
  text-align: center;
}

.story-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
}

.story-title {
  font-size: 32px;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}

.story-paragraph {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 500;
}

.cta-button {
  margin-top: 20px;
  font-size: 16px;
  padding: 10px 30px;
  background-color: #858d86;
}

.cta-button:hover {
  background-color: #636863 !important;
}

@media (max-width: 768px) {
  .overlay-text {
    /* right: 50%; */
    top: 90%;
    left: 50%;
    padding: 0;
    width: 100%;
  }
  .overlay-title {
    font-size: 18px;
  }

  .story-title {
    font-size: 28px;
  }

  .story-paragraph {
    font-size: 14px;
  }

  .cta-button {
    font-size: 14px;
    padding: 8px 20px;
  }
}

@media (max-width:576px) {
  .overlay-text {
    /* right: 50%; */
    top: 80%;
    left: 50%;
    padding: 0;
    width: 100%;
  }
}