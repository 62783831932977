/* Ensure the container covers the desired dimensions */
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px; /* You can adjust this value based on your desired height */
  overflow: hidden; /* This ensures any overflow is hidden */

  /* Make sure the image fits correctly */
  .img-fluid {
    width: 100%; /* Fill the container's width */
    height: auto; /* Maintain the aspect ratio */
    object-fit: contain; /* Adjust this to 'cover' if you want to fill the space entirely */
  }
}

/* Media query to handle responsiveness for smaller screens */
@media (max-width: 786px) {
  .img-container {
    height: 150px; /* Adjust for smaller screens */
  }

  .img-fluid {
    width: 100%; /* Maintain full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain;
  }
}
