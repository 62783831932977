.testimonial-container {
  perspective: 1200px;
  /* Controls the depth of the 3D effect */
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* Adjust based on your needs */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

.testimonial-wrapper {
  display: flex;
  flex-direction: row;
  /* Display cards in a horizontal row */
  transition: transform 1s ease-in-out;
  will-change: transform;
  gap: 10px;
  /* Added px unit for gap */
  transform-style: preserve-3d;
  /* Ensures that child elements maintain 3D transforms */
}

.testimonial-card {
  min-width: 300px;
  /* Adjust the width as needed */
  margin: 0 10px;
  /* Space between cards */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  /* Prevents backface from showing during rotation */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Added subtle shadow */
  border-radius: 10px;
  /* Slightly rounded corners */
}

.testimonial-card:hover {
  transform: rotateY(15deg) translateZ(20px);
  /* 3D rotation and translation for hover effect */
}