.terms-of-use-container {
  padding: 20px;
  max-width: 1080px;
  margin: 0 auto;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
}

.terms-of-use-title {
  color: #4295d1 !important;
}

.terms-of-use-paragraph {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 15px;
}

.terms-of-use-list {
  padding-left: 20px;
  margin-bottom: 20px;
  list-style-type: disc;
}

.terms-of-use-list-item {
  margin-bottom: 10px;
}
