.privacy-policy-container {
  padding: 20px;
  max-width: 1080px;
  margin: 0 auto;
  /* background-color: #f9f9f9; */
  border-radius: 8px;

  span {
    font-weight: bold;
    font-size: 16px;
  }
}

.privacy-policy-title {
  color: #4295d1 !important;
  text-align: start;
  margin-bottom: 20px;
}

.privacy-policy-paragraph {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 15px;
}

.privacy-policy-subtitle {
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #1a237e;
}

.privacy-policy-heading {
  font-size: 18px;
}

.privacy-policy-list {
  padding-left: 20px;
  margin-bottom: 20px;
  list-style-type: disc;
}

.privacy-policy-list li {
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}
