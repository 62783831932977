.piercing-image-container {
  position: relative;
  width: 100%;
  max-height: 750px;
  overflow: hidden;
}

.piercing-banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* .overlay-text {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 30px;
  border-radius: 5px;
  text-align: center;
} */

.overlay-title {
  color: #3c6d9c;
  font-size: 20px;
}

.content-container {
  padding: 50px 20px;
}

.story-container {
  background-color: #fff;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.story-title {
  font-size: 32px;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}

.story-paragraph {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
}

.section-title {
  font-size: 24px;
  margin-top: 40px;
  text-align: center;
  letter-spacing: 1.5px;
}

.section-paragraph {
  font-size: 16px;
  margin: 0;
  text-align: center;
}

.features-row {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.feature-item {
  text-align: left;
  max-width: 300px;
}

.feature-title {
  font-size: 20px;
  margin-top: 10px;
  height: 60px;
}

.feature-paragraph {
  font-size: 16px;
  line-height: 1.6;
}

.aftercare-section {
  margin-top: 40px;
}

.aftercare-title {
  font-size: 24px;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}

.aftercare-paragraph {
  font-size: 16px;
  max-width: 800px;
  margin-bottom: 20px;
}

.aftercare-image {
  margin-top: 20px;
  max-width: 100%;
}

.aftercare-note {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
}

@media (max-width: 768px) {
  /* .overlay-text {
    left: 50%;
    padding: 5px;
    width: 100%;
  } */
  .overlay-title {
    font-size: 18px;
  }

  .story-title {
    font-size: 28px;
  }

  .section-title,
  .aftercare-title {
    font-size: 20px;
  }

  .story-paragraph,
  .feature-paragraph,
  .aftercare-paragraph {
    font-size: 14px;
  }

  .features-row {
    flex-direction: column;
    gap: 40px;
  }

  .feature-item {
    max-width: 100%;
  }
}
