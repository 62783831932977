#navbar {
  background: var(--background-color);
  /* padding: 10px 20px; */
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100px;
}

.navbar-logo {
  cursor: pointer;
  color: var(--primary-color);
  font-weight: bold;
  margin: 0 !important;
  padding: 0 2rem;
  white-space: nowrap;
  max-width: 300px;
}

.navbar-left,
.navbar-right {
  border: none;
  width: 100%;
  max-width: 100%;
  font-weight: bold;
  font-size: 1.2rem;
}

.navbar-left {
  justify-content: center;
}

.navbar-right {
  justify-content: end;
}

.navbar-left .ant-menu-item,
.navbar-right .ant-menu-item,
.navbar-left .ant-menu-submenu-title,
.navbar-right .ant-menu-submenu-title {
  color: var(--accent-color) !important;
}

.navbar-left .ant-menu-item:hover,
.navbar-right .ant-menu-item:hover,
.navbar-left .ant-menu-item-active,
.navbar-right .ant-menu-item-active,
.navbar-left .ant-menu-item-selected,
.navbar-right .ant-menu-item-selected {
  background: transparent !important;
  color: var(--primary-color) !important;
}

.navbar-left .ant-menu-horizontal {
  border-bottom: none;
}

.navbar-search {
  position: relative;
  width: 300px;
  margin-right: 20px;
}

.navbar-search .ant-input {
  /* background: var(--input-background); */
  color: var(--accent-color);
  border-radius: 5px;
}

.ant-input-search-button {
  background: var(--primary-color);
  color: var(--background-color);
}

.navbar-searchlist {
  position: absolute;
  background-color: white;
  z-index: 2;
  top: 3.5rem;
  left: 0;
  padding: 1rem 3rem 1rem 1rem;
  width: 100%;
  background: var(--background-color);
  color: var(--text-color);
}

.navbar-searchlist .ant-list-item {
  background: var(--background-color);
  color: var(--text-color);
}

.navbar-searchlist .ant-list-item-meta-title a {
  color: var(--primary-color);
}

.navbar-searchlist .ant-list-item-meta-description {
  color: var(--secondary-color);
}

#drawer-container {
  display: none;
}

#drawer-container .ant-btn {
  color: var(--primary-color);
  border: none;
}

.ant-drawer-header {
  background: var(--background-color);
  color: var(--text-color);
}

.ant-drawer-body {
  background: var(--background-color);
  color: var(--text-color);
}

.ant-drawer-title {
  color: var(--text-color);
}

/* Responsive Styling */
@media (max-width: 1020px) {
  #navbar {
    .navbar-left {
      display: none;
    }

    .navbar-right {
      display: none;
    }
  }

  #drawer-container {
    display: flex;
    padding: 1rem;
  }

  .navbar-mobile-left {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .ant-drawer-content-wrapper {
    max-width: 50% !important;
    height: min-content;
  }

  .ant-drawer-body {
    padding: 0 !important;
  }

  .navbar-mobile-left .ant-menu-item,
  .navbar-mobile-left .ant-menu-submenu-title {
    color: var(--accent-color) !important;
    font-size: 0.8rem; /* Set the font size */
    font-weight: bold; /* Set the font weight */
    text-wrap: wrap;
    line-height: normal;
  }

  .navbar-mobile-left .ant-menu-item:hover,
  .navbar-mobile-left .ant-menu-item-active,
  .navbar-mobile-left .ant-menu-item-selected {
    background: transparent !important;
    color: var(--primary-color) !important;
  }
}

@media (max-width: 786px) {
  #navbar {
    .navbar-logo {
      margin: 0;
      padding: 1rem;
    }
  }

  #drawer-container {
    .navbar-search-menu {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  #navbar {
    .navbar-search {
      display: none;
    }
  }

  #drawer-container {
    .navbar-search-menu {
      display: flex;
    }
  }
}
