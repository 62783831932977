@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* colors.css */
:root {
  --primary-color: #4aa3fe;
  --secondary-color: #a2a3a4;
  --accent-color: #b2afc4;
  --text-color: #ffffff;
  --background-color: white;
  --input-background: #2c2c2e;
  --price-color: #000;
  --discount-color: #a2a3a4;
}

/* :root {
  --color-primary: #1e90ff;
  --color-secondary: #ffa07a;
  --color-accent: #ff7f50;
  --color-background: #ffffff;
  --color-border: #f5f5f5;
  --color-text: #696969;

  --btn-primary-bg: var(--color-primary);
  --btn-secondary-bg: var(--color-secondary);
  --btn-accent-bg: var(--color-accent);

  --alert-bg: var(--color-accent);
  --alert-border-color: var(--color-secondary);
  --alert-text-color: var(--color-background);
} */

body {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

p,
span,
div,
a,
button {
  font-family: "Roboto", sans-serif;
}
