body {
  background-color: var(--background-color);
}

.carousel-container {
  /* background: linear-gradient(180deg, #ffdab9 0%, #6fb3e8 100%); */
  height: 800px;
}

/* .img-container {
  background-color: transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.img-container:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

.button {
  background-color: #ffdab9;
  /* Logo Blue */
  color: #1e90ff;
  /* White text for high visibility */
  border: 2px solid #003d66;
  /* Dark Blue border for contrast */
  font-weight: bold;
  padding: 10px 20px;
  /* Make text stand out */
  border-radius: 5px;
  /* Rounded corners for a modern look */
  cursor: pointer;
  /* Indicate it's clickable */
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition on hover */
}

.button:hover {
  background-color: #1565c0;
  /* Slightly darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Add a shadow on hover */
}

.button:active {
  background-color: #0d47a1;
  /* Even darker blue when pressed */
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Inset shadow for a pressed effect */
}

.steps-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 15px;
}

.steps-images {
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 3px solid #ffffff;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.steps-images:hover {
  transform: scale(1.05);
}

.section-separator {
  height: 2px;
  background: linear-gradient(90deg, #ffffff 0%, #dcdcdc 50%, #ffffff 100%);
  margin: 40px 0;
}

@media only screen and (max-width: 768px) {
  .carousel-container {
    height: 400px;
  }
}

@media only screen and (max-width: 480px) {
  .carousel-container {
    height: 300px;
  }
}
